import BaseModel from "@/models/base-model.js";

export default class Stock extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
    code_hexa: {
      type: "text",
      title: "Warna Palet",
      label: "code_hexa",
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "stock-transactions";
}
